import React from 'react';
import {ChevronDoubleRightIcon} from '@heroicons/react/outline';

export default function Example() {
  return (
    <div className="bg-black">
      <div className="px-4 py-12 mx-auto text-center max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          <span className="block">Ready to give it a try?</span>
          <span className="block">Check out our live demo.</span>
        </h2>
        <div className="max-w-md mx-auto mt-5 sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <a
              href="https://docs.dfsnft.com"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-full px-8 py-3 text-sm font-bold tracking-widest text-gray-900 border border-transparent rounded-md bg-pastelBlue font-button hover:bg-pastelBlue-darker md:py-3 md:text-sm md:px-10"
            >
              LEARN MORE
              <ChevronDoubleRightIcon className="w-4 h-4 ml-4" />
            </a>
          </div>
          <div className="mt-3 rounded-md shadow-md sm:mt-0 sm:ml-5">
            <a
              href="/"
              className="flex items-center justify-center w-full px-8 py-3 text-sm font-bold tracking-widest text-gray-900 bg-white border border-transparent rounded-md font-button hover:bg-gray-100 hover:text-bg-gray-900 md:py-3 md:text-sm md:px-10"
            >
              LIVE DEMO
              <ChevronDoubleRightIcon className="w-4 h-4 ml-4" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default function Example() {
//   return (
//     <div className="bg-white">
//       <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
//         <div className="overflow-hidden bg-gray-900 rounded-lg shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
//           <div className="px-6 pt-10 pb-12 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
//             <div className="lg:self-center">
//               <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
//                 <span className="block">Ready to dive in?</span>
//                 <span className="block">Check out our live demo now.</span>
//               </h2>
//               <p className="mt-4 text-lg leading-6 text-gray-200">
//                 Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
//                 Malesuada adipiscing sagittis vel nulla nec.
//               </p>
//               <a
//                 href="https://demo.dfsnft.com"
//                 className="inline-flex items-center px-5 py-3 mt-8 text-base font-medium text-white border border-transparent rounded-md shadow font-button bg-pastelBlue hover:bg-pastelBlue-darker"
//               >
//                 LIVE DEMO
//                 <ChevronDoubleRightIcon className="w-4 h-4 ml-4" />
//               </a>
//             </div>
//           </div>
//           <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
//             <img
//               className="object-cover object-left-top transform translate-x-6 translate-y-6 rounded-md sm:translate-x-16 lg:translate-y-20"
//               src="./images/appshot.png"
//               alt="App screenshot"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
