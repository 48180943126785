import React, {Fragment} from 'react';
import {Popover, Transition} from '@headlessui/react';
import {
  MenuIcon,
  XIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/outline';

const navigation = [
  {name: 'Twitter', href: 'https://twitter.com/dfsnft', target: '_blank'},
  {name: 'Discord', href: 'https://discord.gg/4wkrzQwBbN', target: '_blank'},
  {name: 'Github', href: 'https://github.com/dfsnft', target: '_blank'},
  {
    name: 'Documentation',
    href: 'https://docs.dfsnft.com',
    target: '_blank',
  },
];

export default function Hero() {
  return (
    <div className="bg-gray-50">
      <div className="relative overflow-hidden">
        <div className="absolute inset-y-0 w-full h-full" aria-hidden="true">
          <div className="relative h-full">
            <svg
              className="absolute transform right-full translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
              />
            </svg>
            <svg
              className="absolute transform left-full -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
              />
            </svg>
          </div>
        </div>

        <div className="relative pt-6 pb-16 sm:pb-24">
          <Popover>
            {({open}) => (
              <>
                <div className="px-4 mx-auto max-w-7xl sm:px-6">
                  <nav
                    className="relative flex items-center justify-between sm:h-10 md:justify-center"
                    aria-label="Global"
                  >
                    <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <a href="/">
                          <span className="sr-only">Workflow</span>
                          <img
                            className="w-auto h-8 sm:h-10"
                            src="./images/logo.svg"
                            alt=""
                          />
                        </a>
                        <div className="flex items-center -mr-2 md:hidden">
                          <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md bg-gray-50 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pastelBlue-darker">
                            <span className="sr-only">Open main menu</span>
                            <MenuIcon className="w-6 h-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:flex md:space-x-10">
                      {navigation.map(item => (
                        <a
                          key={item.name}
                          href={item.href}
                          target={item.target}
                          rel="noreferrer"
                          className="font-medium text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                      <span className="inline-flex rounded-md shadow-md">
                        <a
                          href="/"
                          className="inline-flex items-center px-4 py-2 text-sm font-bold tracking-widest text-gray-900 bg-white border border-transparent rounded-md font-button hover:bg-gray-900 hover:text-white"
                        >
                          DEMO
                          <ChevronDoubleRightIcon className="w-4 h-4 ml-2" />
                        </a>
                      </span>
                    </div>
                  </nav>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel
                    focus
                    static
                    className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden"
                  >
                    <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                      <div className="flex items-center justify-between px-5 pt-4">
                        <div>
                          <img
                            className="w-auto h-8"
                            src="./images/logo.svg"
                            alt=""
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pastelBlue-darker">
                            <span className="sr-only">Close main menu</span>
                            <XIcon className="w-6 h-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="px-2 pt-2 pb-3 space-y-1">
                        {navigation.map(item => (
                          <a
                            key={item.name}
                            href={item.href}
                            target={item.target}
                            rel="noreferrer"
                            className="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                      <a
                        href="/"
                        className="block w-full px-5 py-3 font-medium text-center text-gray-900 font-button bg-gray-50 hover:bg-gray-100 hover:text-gray-900"
                      >
                        LIVE DEMO
                      </a>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <div className="px-4 mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6">
            <div className="text-center">
              <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl sm:space-y-2">
                <span className="block">Daily Fantasy Sports</span>
                <span className="block text-pastelBlue-darker">
                  Non-Fungible Token
                </span>
              </h1>
              <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Collect crypto trading cards of your favorite athletes. Play
                them in daily fantasy sports contests to win money, level up,
                and increase your card&apos;s value.
              </p>
              <div className="max-w-md mx-auto mt-5 sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <a
                    href="https://docs.dfsnft.com"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center w-full px-8 py-3 text-sm font-bold tracking-widest text-white bg-gray-900 border border-transparent rounded-md font-button hover:bg-pastelBlue hover:text-gray-900 md:py-4 md:text-sm md:px-10"
                  >
                    LEARN MORE
                    <ChevronDoubleRightIcon className="w-4 h-4 ml-4" />
                  </a>
                </div>
                <div className="mt-3 rounded-md shadow-md sm:mt-0 sm:ml-5">
                  <a
                    href="/"
                    className="flex items-center justify-center w-full px-8 py-3 text-sm font-bold tracking-widest text-gray-900 bg-white border border-transparent rounded-md font-button hover:bg-gray-900 hover:text-white md:py-4 md:text-sm md:px-10"
                  >
                    LIVE DEMO
                    <ChevronDoubleRightIcon className="w-4 h-4 ml-4" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="flex-1 w-full bg-gray-900" />
          </div>
          <div className="px-4 mx-auto max-w-7xl sm:px-6">
            <img
              className="relative border border-gray-100 rounded-lg shadow-lg"
              src="./images/screenshot.png"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-900">
        <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
          <h2 className="text-sm font-semibold tracking-wide text-center text-white uppercase">
            Built on the best
          </h2>
          <div className="grid grid-cols-2 gap-8 mt-8 md:grid-cols-4">
            <div className="flex items-center justify-center col-span-1">
              <img
                className="h-10"
                src="./images/companies/aave.svg"
                alt="Aave"
              />
            </div>
            <div className="flex justify-center col-span-1">
              <img
                className="h-12"
                src="./images/companies/chainlink.svg"
                alt="Chainlink"
              />
            </div>
            <div className="flex justify-center col-span-1">
              <img
                className="h-12"
                src="./images/companies/arbitrum.svg"
                alt="Arbitrum"
              />
            </div>
            <div className="flex items-center justify-center col-span-1 space-x-3">
              <img
                className="h-12"
                src="./images/companies/ipfs-box.svg"
                alt="IPFS Box"
              />
              <img
                className="h-12"
                src="./images/companies/ipfs-text.svg"
                alt="IPFS"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
