import React from 'react';
import Hero from './Hero';
import Footer from './Footer';
import Features from './Features';
import CTA from './CTA';

export default function New() {
  return (
    <>
      <Hero />
      <Features />
      <CTA />
      <Footer />
    </>
  );
}
